'use strict';

/*
    This file can be used as entry point for webpack!
 */

import $ from 'jquery';
import Swiper from 'swiper';

let slider = new Swiper('.swiper-container', {
    loop: true,

    pagination: {
        el: '.swiper-pagination',
    },

    autoplay: {
        delay: 5000,
    },
    centeredSlides: 1,
    spaceBetween: 400,
    slidesPerView: 1,

    on: {
        init() {
            //
        },
    },
});

let header = document.querySelector('.header');

document.querySelectorAll('.smooth').forEach((elem) => {
    let target = document.getElementById(elem.dataset.scroll);
    elem.addEventListener('click', function(e) {
        e.preventDefault();
        let start = window.pageYOffset,
            finish = target.offsetTop,
            durationTime = 400; // ms
        let step = (finish - start) / 20;
        let i = parseInt(target.offsetTop);
        let int = setInterval(function() {
            start += step;
            window.scrollTo(0, start);
            console.debug(start, finish, step);
            if (start >= finish) {
                clearInterval(int);
                header.classList.remove('-menu');
            }
        }, 20);
    });
});

document.querySelector('#burger').addEventListener('click', (e) => {
    e.preventDefault();
    header.classList.toggle('-menu');
});

$('.form').on('submit', function(e) {
    e.preventDefault();
    const $this = $(this),
        formArray = $this.serializeArray();

    for (let i = formArray.length - 1; i >= 0; i--) {
        if (formArray[i].name === 'phone' && formArray[i].value.length < 7) {
            // window.alert('Укажите телефон');
            $this
                .find('[name="phone"]')
                .addClass('invalid')
                .focus();
            return;
        }
    }

    const data = {
        f: formArray,
    };

    $.post('/mail.php', data, function(response) {
        response = JSON.parse(response);
        if (response.status === 'ok') {
            window.alert('Заявка отправлена!');
        }
    });
});
